import { Component, forwardRef, inject, Input, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective, FormsModule, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { BaseInput, controlContainerFactory } from '../base-input';
import { ErrorComponent } from '../error/error.component';
import { FieldsetComponent } from '../fieldset/fieldset.component';

@Component({
    selector: 'ui-v2-text-input',
    standalone: true,
    template: `
        <ui-v2-fieldset class="relative w-full" [legend]="legend" [label]="label">
            <input
                type="text"
                class="input w-full"
                [class.input-error]="showError"
                [(ngModel)]="innerModel"
                (ngModelChange)="onModelChange($event)"
                (blur)="onBlur()"
                [disabled]="innerDisabled"
                [attr.placeholder]="placeholder"
            />

            <ui-v2-input-error [showError]="showError" [error]="error"></ui-v2-input-error>
        </ui-v2-fieldset>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextInputComponent),
            multi: true,
        },
        {
            provide: ControlContainer,
            useFactory: controlContainerFactory,
            deps: [
                [new Optional(), new SkipSelf(), FormGroupDirective],
                [new Optional(), new SkipSelf(), NgForm],
            ],
        },
    ],
    imports: [FormsModule, ErrorComponent, FieldsetComponent],
})
export class TextInputComponent extends BaseInput<string> {
    public innerDisabled: boolean;

    @Input() formControl: FormControl;
    @Input() formControlName: string;
    @Input() legend: string;
    @Input() label: string;
    @Input() placeholder: string = '';

    controlContainer = inject(ControlContainer);

    public setDisabledState(isDisabled: boolean): void {
        this.innerDisabled = isDisabled;
    }

    public writeValue(value: string): void {
        this.innerModel = value ?? null;
    }

    public onModelChange(value: string): void {
        this.propagateChange(value);
    }
}
