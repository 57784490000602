import { Component, forwardRef, Host, Input, OnInit, Optional, signal, SkipSelf } from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { takeUntil } from 'rxjs';
import { BaseInput } from '../../../form/base-input';
import { SvgIllustration } from '../../../svg/components/svg-illustration/svg-illustration.enum';
import { gasControlNames, GasMetersForm, placeholders } from '../../constants/energy.constants';
import { ElectricityMeterType } from '@ui/energy';
import { CheckInput } from '@ui/form';
import { UiLabelPosition } from '@ui/ui.enums';

@Component({
    selector: 'smvd-ui-meter-readings-gas',
    templateUrl: 'meter-readings-gas.component.html',
    styleUrls: ['meter-readings-gas.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MeterReadingsGasComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MeterReadingsGasComponent),
            multi: true,
        },
    ],
})
export class MeterReadingsGasComponent extends BaseInput implements OnInit, ControlValueAccessor, Validator {
    @Input() initialMeterType: ElectricityMeterType;

    public readonly fields = gasControlNames;
    public readonly svgIllustration = SvgIllustration;
    public readonly placeholders = placeholders;
    public automaticDigitalReadingOption: CheckInput<boolean> = {
        id: 'automatic-digital-readings-gas',
        name: 'automatic-digital-readings-gas',
        value: true,
        labelResource: 'ENERGY.METER_READING.DIGITAL.AUTOMATIC_READING',
    };
    public gasForm: FormGroup = this.formBuilder.group({
        [gasControlNames.active]: true,
        [gasControlNames.eanCodeGas]: null,
        [gasControlNames.meterNumberGas]: null,
        [gasControlNames.gasMeterReading]: null,
        [gasControlNames.automaticDigitalReadings]: false,
    });

    public activeControl: FormControl;
    public meterTypeDigital = false;

    public gasMeterNumberInputVisible = signal(false);

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private readonly formBuilder: FormBuilder
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.activeControl = this.gasForm.get(gasControlNames.active) as FormControl;
        this.gasForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.handleFormValueChange);
        this.meterTypeDigital = this.initialMeterType === ElectricityMeterType.Digital;
    }

    public writeValue(data: GasMetersForm): void {
        this.gasForm.patchValue(data, { emitEvent: false });
        this.gasMeterNumberInputVisible.set(!!data && !!data[gasControlNames.meterNumberGas]);
    }

    public validate(): ValidationErrors | null {
        return this.gasForm.invalid ? { gasMeters: true } : null;
    }

    public setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.gasForm.disable({ emitEvent: false }) : this.gasForm.enable({ emitEvent: false });
    }

    public meterTypeChanged(meterType: ElectricityMeterType): void {
        this.meterTypeDigital = meterType === ElectricityMeterType.Digital;
    }

    public showGasMeterNumberInput(event: Event): void {
        event.preventDefault();
        this.gasMeterNumberInputVisible.set(true);
    }

    private handleFormValueChange = (): void => this.propagateChange(this.gasForm.getRawValue());
    protected readonly UiLabelPosition = UiLabelPosition;
}
