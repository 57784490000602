import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { BadgeModule } from '../badge';
import { ButtonModule } from '../button/button.module';
import { DirectivesModule } from '../directives';
import { IconModule } from '../icon';
import { PipeModule } from '../pipes';
import { SvgModule } from '../svg';
import { CheckInputComponent } from './check-input/check-input.component';
import { DropdownInputComponent } from './dropdown-input/dropdown-input.component';
import { DynamicAddressInputComponent } from './dynamic-address-input/dynamic-address-input.component';
import { MeterReadingInputComponent } from './meter-reading-input/meter-reading-input.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { RestrictInputDirective } from './restrict-input/restrict-input.directive';
import { ScoreInputComponent } from './score-input/score-input.component';
import { TermsAndConditionsInputComponent } from './terms-and-conditions-input/terms-and-conditions-input.component';
import { TextSearchInputComponent } from './text-search-input/text-search-input.component';
import { TextareaInputComponent } from './textarea-input/textarea-input.component';
import { SearchableDropdownInputModule } from './searchable-dropdown-input';
import { LabelContainerModule } from './label-container';
import { ErrorStateModule } from './error-state';
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';
import { SelectInputModule } from '@ui/form/select-input/select-input.module';
import { AddressInputComponent } from '@ui/form/address-input/address-input.component';
import { TextInputComponent } from '@ui/form/text-input/text-input.component';
import { AddressStreetInputComponent } from '@ui/form/address-street-input/address-street-input.component';
import { DateInputComponent } from '@ui/form/date-input/date-input.component';
import { EmailInputComponent } from '@ui/form/email-input/email-input.component';
import { EanCodeInputComponent } from '@ui/form/ean-code-input/ean-code-input.component';

const modules = [
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatIconModule,
    LabelContainerModule,
    SelectInputModule,
];

const directives = [RestrictInputDirective];

const components = [
    CheckInputComponent,
    TextareaInputComponent,
    TextSearchInputComponent,
    DropdownInputComponent,
    NumberInputComponent,
    TermsAndConditionsInputComponent,
    ScoreInputComponent,
    DynamicAddressInputComponent,
    MeterReadingInputComponent,
    EanCodeInputComponent,
];

@NgModule({
    declarations: [...components, ...directives],
    imports: [
        ...modules,
        CommonModule,
        TranslateModule,
        MatInputModule,
        MatFormFieldModule,
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule,
        SvgModule,
        MatCheckboxModule,
        IconModule,
        ButtonModule,
        PipeModule,
        BadgeModule,
        NgxMaskDirective,
        AutocompleteLibModule,
        DirectivesModule,
        ErrorStateModule,
        SearchableDropdownInputModule,
        TextInputComponent,
        AddressInputComponent,
        AddressStreetInputComponent,
        DateInputComponent,
        EmailInputComponent,
        PhoneInputComponent,
    ],
    exports: [
        ...modules,
        ...components,
        ...directives,
        SearchableDropdownInputModule,
        ErrorStateModule,
        AddressInputComponent,
        TextInputComponent,
        AddressStreetInputComponent,
        DateInputComponent,
        EmailInputComponent,
        PhoneInputComponent,
    ],
    providers: [provideEnvironmentNgxMask()],
})
export class UiFormModule {}
