<form id="gas-form" class="__gas-form" [formGroup]="gasForm">
    <div class="u-flex-row u-flex-justify-content-space-between u-margin-bottom">
        <div class="u-flex-row u-flex-justify-content-flex-start">
            <smvd-ui-svg-illustration
                [svg]="svgIllustration.MetersGas"
                class="u-icon-24 u-margin-right-half"
                [class.u-color-gray-dark]="activeControl.value"
                [class.u-color-gray-light]="!activeControl.value"
            ></smvd-ui-svg-illustration>
            <h5 [class.u-color-gray-light]="!activeControl.value">{{ 'GAS' | translate }}</h5>
        </div>
        <mat-slide-toggle *ngIf="gasForm.enabled" [formControlName]="fields.active" color="primary"
                          disableRipple="true"></mat-slide-toggle>
    </div>

    <ng-container *ngIf="activeControl.value; else inactive">
        <app-ean-code-input
            id="ean-code-field"
            width="100%"
            [formControlName]="fields.eanCodeGas"
            [label]="'V2.SERVICES.METER_READINGS.UPDATE.EAN_CODE.LABEL' | translate"
            [placeholder]="placeholders.eanPlaceholder"
        ></app-ean-code-input>

        <div class="u-margin-bottom-double">
            @if (gasMeterNumberInputVisible()) {
                <smvd-ui-text-input
                    id="meter-number-field"
                    width="100%"
                    [formControlName]="fields.meterNumberGas"
                    [label]="'V2.SERVICES.METER_READINGS.UPDATE.METER_NUMBER.LABEL' | translate"
                    [placeholder]="'V2.SERVICES.METER_READINGS.UPDATE.METER_NUMBER.PLACEHOLDER' | translate"
                ></smvd-ui-text-input>
                <p class="text-text-secondary text-body-md">
                    {{ 'V2.SERVICES.METER_READINGS.UPDATE.METER_NUMBER.RECOMMENDATION' | translate }}
                </p>
            } @else {
                <a class="text-body-md text-text-secondary cursor-pointer"
                   (click)="showGasMeterNumberInput($event)"
                >{{ 'V2.SERVICES.METER_READINGS.UPDATE.EAN_CODE.ADD_METER_NUMBER.LABEL' | translate }}
                    <span class="text-text-interactive underline cursor-pointer hover:text-text-interactive-hover">{{
                            'V2.SERVICES.METER_READINGS.UPDATE.EAN_CODE.ADD_METER_NUMBER.CTA' | translate
                        }}</span>
                </a>
            }
        </div>

        <smvd-ui-meter-reading-input
            width="100%"
            [formControlName]="fields.gasMeterReading"
            [label]="'METER_READING' | translate"
            [placeholder]="placeholders.consumptionMcLabel | translate"
        ></smvd-ui-meter-reading-input>

        @if (meterTypeDigital) {
            <app-check-input
                [custom]="false"
                width="auto"
                [labelPosition]="UiLabelPosition.After"
                [formControlName]="fields.automaticDigitalReadings"
                [option]="automaticDigitalReadingOption"
            ></app-check-input>
        }
    </ng-container>
</form>

<ng-template #inactive
><p class="u-color-gray-dark" id="inactive-text">{{ 'ENERGY.METER_READING.INACTIVE.GAS' | translate }}</p></ng-template
>
