import { Component, EventEmitter, forwardRef, Host, Input, OnInit, Optional, Output, signal, SkipSelf } from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MoveTransactionType, TransactionType } from '@smooved/core';
import {
    automaticDigitalReadingOptionFn,
    CheckInput,
    ElectricityForm,
    ElectricityFormFields,
    electricityMeterTypeOptions,
    energyI18n,
    exclusiveNightOptionFn,
    ModalSandbox,
    placeholders,
    hasInjectionOption,
    SvgIllustration,
    UiAlignment,
    UiDirection,
} from '@smooved/ui';
import { takeUntil } from 'rxjs';
import { BaseInput } from '../../../form/base-input';
import { ElectricityMeterType } from '../../enums/electricity-meter-type.enum';

@Component({
    selector: 'smvd-ui-meter-readings-electricity',
    templateUrl: 'meter-readings-electricity.component.html',
    styleUrls: ['meter-readings-electricity.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MeterReadingsElectricityComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MeterReadingsElectricityComponent),
            multi: true,
        },
    ],
})
export class MeterReadingsElectricityComponent extends BaseInput implements OnInit, ControlValueAccessor, Validator {
    @Input() public transactionType: MoveTransactionType | TransactionType = MoveTransactionType.Sale;
    @Input() public showAutoReadout: boolean = false;
    @Output() public meterTypeChanged: EventEmitter<ElectricityMeterType> = new EventEmitter<ElectricityMeterType>();

    public readonly fields = ElectricityFormFields;
    public readonly svgIllustration = SvgIllustration;
    public readonly uiDirection = UiDirection;
    public readonly meterType = ElectricityMeterType;
    public readonly placeholders = placeholders;
    public readonly alignment = UiAlignment;
    public readonly hasInjectionOption = hasInjectionOption;

    public readonly electricityForm: FormGroup = this.formBuilder.group({
        [ElectricityFormFields.Active]: true,
        [ElectricityFormFields.MeterType]: ElectricityMeterType.Single,
        [ElectricityFormFields.ExclusiveNight]: false,
        [ElectricityFormFields.EanCode]: null,
        [ElectricityFormFields.MeterNumberElectricity]: null,
        [ElectricityFormFields.AutomaticDigitalReadings]: false,
        [ElectricityFormFields.MovingDate]: null,
        [ElectricityFormFields.SingleMeterReading]: null,
        [ElectricityFormFields.DoubleDayMeterReading]: null,
        [ElectricityFormFields.DoubleNightMeterReading]: null,
        [ElectricityFormFields.ConsumptionDayMeterReading]: null,
        [ElectricityFormFields.ConsumptionNightMeterReading]: null,
        [ElectricityFormFields.HasInjection]: null,
        [ElectricityFormFields.InjectionDayMeterReading]: null,
        [ElectricityFormFields.InjectionNightMeterReading]: null,
        [ElectricityFormFields.ExclusiveNightMeterReading]: null,
    });

    public activeControl: FormControl<boolean>;
    public meterTypeControl: FormControl<ElectricityMeterType>;
    public hasInjectionControl: FormControl<boolean>;
    public exclusiveNightControl: FormControl<boolean>;

    public exclusiveNightOption: CheckInput<boolean>;
    public automaticDigitalReadingOption: CheckInput<boolean>;
    public meterTypeOptions = electricityMeterTypeOptions;

    public electricityMeterNumberInputVisible = signal(false);

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private readonly formBuilder: FormBuilder,
        private readonly translate: TranslateService
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.exclusiveNightOption = exclusiveNightOptionFn(`${this.translate.instant(energyI18n.exclusiveNight)}`);
        this.automaticDigitalReadingOption = automaticDigitalReadingOptionFn(this.transactionType as MoveTransactionType);

        this.activeControl = this.electricityForm.get(ElectricityFormFields.Active) as FormControl;
        this.meterTypeControl = this.electricityForm.get(ElectricityFormFields.MeterType) as FormControl;
        this.exclusiveNightControl = this.electricityForm.get(ElectricityFormFields.ExclusiveNight) as FormControl;
        this.hasInjectionControl = this.electricityForm.get(ElectricityFormFields.HasInjection) as FormControl;

        this.electricityForm
            .get(ElectricityFormFields.MeterType)
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value) => {
                this.meterTypeChanged.emit(value);
            });

        this.electricityForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.handleFormValueChange);
    }

    public writeValue(data: ElectricityForm): void {
        this.electricityForm.patchValue(data, { emitEvent: false });
        this.electricityMeterNumberInputVisible.set(!!data && !!data[ElectricityFormFields.MeterNumberElectricity]);
    }

    public validate(): ValidationErrors | null {
        return this.electricityForm.invalid ? { electricityMeters: true } : null;
    }

    public setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.electricityForm.disable({ emitEvent: false }) : this.electricityForm.enable({ emitEvent: false });
    }

    public showElectricityMeterNumberInput(event: Event): void {
        event.preventDefault();
        this.electricityMeterNumberInputVisible.set(true);
    }

    private handleFormValueChange = (): void => this.propagateChange(this.electricityForm.getRawValue());
}
