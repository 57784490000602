import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    FormGroupDirective,
    FormGroupName,
    NgForm,
} from '@angular/forms';
import { ArrayUtils } from '@core/utils';
import { inject } from '@angular/core';

export class BaseInput<T> implements ControlValueAccessor {
    private parentFormGroupName = inject(FormGroupName, { optional: true });

    public controlContainer: ControlContainer;
    public innerModel: T;
    public formControl: FormControl;
    public formControlName: string;
    public innerDisabled: boolean;

    public setDisabledState(isDisabled: boolean): void {
        this.innerDisabled = isDisabled;
    }

    public get showError(): boolean {
        return this.abstractControl?.invalid && (this.controlContainer?.['submitted'] || this.abstractControl.touched);
    }

    public get error(): string {
        if (!this.abstractControl) {
            return null;
        }

        return ArrayUtils.first(Object.keys(this.abstractControl.errors || {}));
    }

    public onBlur(): void {
        this.propagateTouched();
    }

    public registerOnChange(fn: (_: any) => {}): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => {}): void {
        this.propagateTouched = fn;
    }

    public writeValue(_: any): void {}

    public propagateChange = (_: any) => {};
    public propagateTouched = () => {};

    protected get abstractControl(): AbstractControl | null {
        if (this.formControl) {
            return this.formControl;
        }

        if (!this.controlContainer?.control || !this.formControlName) {
            return null;
        }

        // Start with the parentFormGroupName if it exists, otherwise directly use the container control
        let parentControl: AbstractControl | null = this.controlContainer.control;

        if (this.parentFormGroupName?.name) {
            // parentFormGroupName might also be nested within other FormGroupNames
            parentControl = this.findControlContainer(this.parentFormGroupName);
        }

        return parentControl?.get(this.formControlName) ?? null;
    }

    /**
     * Find correct nested parent AbstractControl from the provided FormGroupName
     */
    private findControlContainer(groupName: FormGroupName): AbstractControl | null {
        const names: string[] = [];

        let container: any = groupName;
        while (container && container.name) {
            names.unshift(container.name.toString());
            container = container.parent;
        }

        // Traversing explicitly through each nested FormGroup
        let control: AbstractControl | null = this.controlContainer.control;
        for (const name of names) {
            if (control instanceof FormGroup && control.controls[name]) {
                control = control.get(name);
            } else {
                // Path is invalid at some point; return null
                return null;
            }
        }

        return control;
    }
}

export function controlContainerFactory(formGroupDirective: FormGroupDirective | null, ngForm: NgForm | null): ControlContainer | null {
    return formGroupDirective || ngForm;
}
